import { io } from 'socket.io-client'
import { useIdentity } from '@/modules/arv/identity'
import getConfig from 'next/config'
import { useEffect } from 'react'
const { publicRuntimeConfig } = getConfig()

const apiUrl = publicRuntimeConfig.apiUrl ? new URL(publicRuntimeConfig.apiUrl) : new URL('https://example.com')
const socket = io(`${apiUrl.protocol}//${apiUrl.host}`, {
    path: apiUrl.pathname === '/' ? `/realtime` : `${apiUrl.pathname}/realtime`,
    autoConnect: false,
    withCredentials: true,
})
export function useSocket() {
    const { token } = useIdentity()

    useEffect(() => {
        socket.io.opts.extraHeaders = {
            authorization: 'Bearer ' + token,
        }
        return () => {
            if (socket.connected) {
                socket.close()
            }
        }
    }, [token])

    return socket
}
