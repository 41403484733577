import { send } from './microservice'
import { GetLookupInDto, GetLookupOutDto } from '@interface/dto/mis'
import { OfficerGetDto, UserProfileDto } from '@interface/dto/am.dto'
import {
	CreateIncidentDtoIn,
	GetChartIncidentSearchPrivateDtoIn,
	GetChartIncidentSearchPublicDtoIn,
	GetIncidentByIdDtoIn,
	GetIncidentDurationByIdDtoIn,
	GetIncidentNotificationDtoIn,
	GetIncidentTagDtoIn,
	GetListIncidentSearchDtoIn,
	GetListIncidentSearchPrivateDtoIn,
	GetListIncidentSearchPublicDtoIn,
	GetLocationIncidentSearchPrivateDtoIn,
	GetLocationIncidentSearchPublicDtoIn,
	GetSummaryIncidentSearchPrivateDtoIn,
	GetSummaryIncidentSearchPublicDtoIn,
	UpdateIncidentReviewDtoIn,
} from '@interface/dto/mis/incident.dto-in'
import {
	ClearIncidentNotificationCountDtoOut,
	CreateIncidentDtoOut,
	DeleteIncidentNotificationDtoOut,
	GetChartIncidentSearchPrivateDtoOut,
	GetChartIncidentSearchPublicDtoOut,
	GetIncidentByIdDtoOut,
	GetIncidentDurationByIdDtoOut,
	GetIncidentNotificationCountDtoOut,
	GetIncidentNotificationDtoOut,
	GetIncidentTagDtoOut,
	GetListIncidentSearchDtoOut,
	GetListIncidentSearchPrivateDtoOut,
	GetListIncidentSearchPublicDtoOut,
	GetLocationIncidentSearchPrivateDtoOut,
	GetLocationIncidentSearchPublicDtoOut,
	GetMunicipalityDtoOut,
	GetSummaryIncidentSearchPrivateDtoOut,
	GetSummaryIncidentSearchPublicDtoOut,
} from '@interface/dto/mis/incident.dto-out'
import { ResponseDto } from '@interface/dto/common'
import { GetAdminPolyOutDto } from '@/components/common/address/interface'
import {
	CheckDuplicationEmailUserManagementDtoOut,
	GetUserManagementDtoOut,
	ListUserManagementDtoOut,
	ListUserNotInOfficerAreaUserManagementDtoOut,
} from '@interface/dto/mis/user-management.dto-out'
import {
	CheckDuplicationEmailUserManagementDtoIn,
	DeleteMappingUserManagementDtoIn,
	GetUserManagementDtoIn,
	InsertMappingUserManagementDtoIn,
	ListUserManagementDtoIn,
	UpdateMappingUserManagementDtoIn,
	UpdateUserManagementDtoIn,
	UpdateUserNotInOfficerAreaDtoIn,
} from '@interface/dto/mis/user-management.dto-in'
import {
	ApproveIncidentOfficerDtoIn,
	AssignIncidentOfficerDtoIn,
	CancelIncidentOfficerDtoIn,
	CreateTaskDtoIn,
	GetIncidentOfficerDtoIn,
	GetUnassignedDtoIn,
	PendingIncidentOfficerDtoIn,
	RejectIncidentOfficerDtoIn,
	ResolveIncidentOfficerDtoIn,
	SearchIncidentOfficerDtoIn,
	UpdateAssignIncidentOfficerDtoIn,
	UrgentIncidentOfficerDtoIn,
} from '@interface/dto/mis/incident-officer.dto-in'
import {
	AssignIncidentOfficerDtoOut,
	GetIncidentOfficerDtoOut,
	GetUnassignedDtoOut,
	ResolveIncidentOfficerDtoOut,
	SearchIncidentOfficerDtoOut,
	UpdateAssignIncidentOfficerDtoOut,
} from '@interface/dto/mis/incident-officer.dto-out'
import {
	CheckDuplicateIncidentProblemListProblemTypeConfigDtoOut,
	GetApproverListIncidentProblemTypeConfigDtoOut,
	GetIncidentProblemTypeConfigDtoOut,
	GetUserListIncidentProblemTypeConfigDtoOut,
	SearchIncidentProblemTypeConfigDtoOut,
} from '@interface/dto/mis/incident-problem-type-config.dto-out'
import {
	CheckDuplicateIncidentProblemListProblemTypeConfigDtoIn,
	DeleteIncidentProblemTypeConfigDtoIn,
	GetApproverListIncidentProblemTypeConfigDtoIn,
	GetIncidentProblemTypeConfigDtoIn,
	GetUserListIncidentProblemTypeConfigDtoIn,
	SearchIncidentProblemTypeConfigDtoIn,
	UpsertIncidentProblemTypeConfigDtoIn,
} from '@interface/dto/mis/incident-problem-type-config.dto-in'
import { AcceptIncidentPeopleDtoIn, RejectIncidentPeopleDtoIn } from '@interface/dto/mis/incident-people.dto-in'
import {
	UpdatePeopleUserDtoIn,
	ValidatePeopleUserDtoIn,
	ValidatePeopleUserDtoOut,
} from '@interface/dto/mis/people-user.dto'
import { UpdateOfficerUserDtoIn } from '@interface/dto/mis/officer-user.dto'
import {
	CheckConfigDivisionDtoIn,
	CreateConfigDivisionDtoIn,
	DeleteConfigDivisionDtoIn,
	GetConfigDivisionDtoIn,
	SearchConfigDivisionDtoIn,
	UpdateConfigDivisionDtoIn,
} from '@interface/dto/mis/config-division.dto-in'
import {
	CheckConfigDivisionDtoOut,
	GetConfigDivisionDtoOut,
	SearchConfigDivisionDtoOut,
} from '@interface/dto/mis/config-division.dto-out'
import {
	CheckConfigProblemDtoOut,
	GetConfigProblemDtoOut,
	SearchConfigProblemDtoOut,
} from '@interface/dto/mis/config-problem.dto-out'
import {
	CheckConfigProblemDtoIn,
	CreateConfigProblemDtoIn,
	DeleteConfigProblemDtoIn,
	GetConfigProblemDtoIn,
	ReorderConfigProblemDtoIn,
	SearchConfigProblemDtoIn,
	UpdateConfigProblemDtoIn,
} from '@interface/dto/mis/config-problem.dto-in'
import {
	AddLineConnectDtoOut,
	DisconnectLineDtoOut,
	GetAddedLineConnectListDtoOut,
	GetDefaultLineOADtoOut,
	GetLineAuthDtoOut,
	GetLineOADtoOut,
	SearchLineOADtoOut,
	SwitchLineMenuDtoOut,
} from '@interface/dto/mis/line.dto-out'
import {
	AddLineConnectDtoIn,
	DeleteLineConnectDtoIn,
	DisconnectLineDtoIn,
	GetLineAuthDtoIn,
	GetLineOADtoIn,
	SearchLineOADtoIn,
	SwitchLineMenuDtoIn,
} from '@interface/dto/mis/line.dto-in'
import { GetUserFileDtoOut } from '@interface/dto/mis/user.dto-out'
import {
	GetMuniSystemConfigDtoIn,
	GetMuniSystemConfigDtoOut,
	UpdateMuniSystemConfigDtoIn,
	UpdateMuniSystemConfigDtoOut,
} from '@interface/dto/mis/muni-system-config.dto'
import {
	GetChartDurationDivisionDtoIn,
	GetChartDurationProblemDtoIn,
	GetChartOperationPersonDtoIn,
	GetChartOperationProblemDtoIn,
	SearchDurationDivisionDtoIn,
	SearchDurationProblemDtoIn,
	SearchOperationPersonDtoIn,
	SearchOperationProblemDtoIn,
} from '@interface/dto/mis/summary.dto-in'
import {
	GetChartDurationDivisionDtoOut,
	GetChartDurationProblemDtoOut,
	GetChartOperationPersonDtoOut,
	GetChartOperationProblemDtoOut,
	SearchDurationDivisionDtoOut,
	SearchDurationProblemDtoOut,
	SearchOperationPersonDtoOut,
	SearchOperationProblemDtoOut,
} from '@interface/dto/mis/summary.dto-out'

export async function syncProfile(isOfficer: boolean = false) {
	if (isOfficer) {
		return send<ResponseDto<UserProfileDto>>('mis.officer-user.upsert').then((res) => res.data)
	}
	return send<ResponseDto<UserProfileDto>>('mis.people-user.upsert').then((res) => res.data)
}

export async function validateAnonymous(payload: ValidatePeopleUserDtoIn) {
	return send<ValidatePeopleUserDtoOut, ValidatePeopleUserDtoIn>('mis.people-user.validate', payload)
}

const cacheLookupData: Record<string, any> = {}
export async function getLookupData<T = GetLookupOutDto>(name: string, getAllStatus = false) {
	if (cacheLookupData[name]) {
		return cacheLookupData[name] as T[]
	}
	try {
		let result = await send<T[], GetLookupInDto>('mis.lookup.get', { name })
		if (name === 'inc-status' && !getAllStatus) {
			result = result.filter((item: any) => item?.id !== 8)
		}
		cacheLookupData[name] = result
		return result
	} catch (err) {
		delete cacheLookupData[name]
		throw err
	}
}

const cacheAdminData: Record<string, any> = {}
export async function getAdminData() {
	return getLookupData<GetAdminPolyOutDto>('admin-poly')
}

export async function getIncidentTag(payload: GetIncidentTagDtoIn) {
	return send<ResponseDto<GetIncidentTagDtoOut>, GetIncidentTagDtoIn>('mis.incident-tag.get', payload).then(
		(res) => res.data.tagList
	)
}

export async function createIncident(payload: CreateIncidentDtoIn) {
	return send<ResponseDto<CreateIncidentDtoOut>, CreateIncidentDtoIn>('mis.inc.create', payload).then(
		(res) => res.data
	)
}

export async function updateIncident(payload: { id: string }) {
	return send<void, { id: string }>('mis.inc.update', payload)
}

export async function getIncidentById(payload: GetIncidentByIdDtoIn) {
	return send<ResponseDto<GetIncidentByIdDtoOut>, GetIncidentByIdDtoIn>('mis.incident-id.get', payload).then(
		(res) => res.data
	)
}

export async function getIncidentDurationById(payload: GetIncidentDurationByIdDtoIn) {
	return send<ResponseDto<GetIncidentDurationByIdDtoOut>, GetIncidentDurationByIdDtoIn>(
		'mis.incident-id.getDuration',
		payload
	).then((res) => res.data)
}

export async function getIncidentOfficer(payload: GetIncidentOfficerDtoIn) {
	return send<ResponseDto<GetIncidentOfficerDtoOut>, GetIncidentOfficerDtoIn>(
		'mis.incident-officer.get',
		payload
	).then((res) => res.data)
}

export async function getListIncidentSearch(payload: GetListIncidentSearchDtoIn) {
	return send<ResponseDto<GetListIncidentSearchDtoOut[]>, GetListIncidentSearchDtoIn>(
		'mis.incident-search.getList',
		payload
	).then((res) => res)
}

export async function getChartPublicSearchIncident(payload: GetChartIncidentSearchPublicDtoIn) {
	return send<ResponseDto<GetChartIncidentSearchPublicDtoOut>, GetChartIncidentSearchPublicDtoIn>(
		'mis.incident-search-public.getChart',
		payload
	).then((res) => res.data)
}

export async function getListIncidentSearchPublic(payload: GetListIncidentSearchPublicDtoIn) {
	return send<ResponseDto<GetListIncidentSearchPublicDtoOut[]>, GetListIncidentSearchPublicDtoIn>(
		'mis.incident-search-public.getList',
		payload
	).then((res) => res)
}

export async function getLocationIncidentSearchPublic(payload: GetLocationIncidentSearchPublicDtoIn) {
	return send<ResponseDto<GetLocationIncidentSearchPublicDtoOut[]>, GetLocationIncidentSearchPublicDtoIn>(
		'mis.incident-search-public.getLocation',
		payload
	).then((res) => res.data)
}

export async function getSummaryIncidentSearchPublic(payload: GetSummaryIncidentSearchPublicDtoIn) {
	return send<ResponseDto<GetSummaryIncidentSearchPublicDtoOut[]>, GetSummaryIncidentSearchPublicDtoIn>(
		'mis.incident-search-public.getSummary',
		payload
	).then((res) => res.data)
}

export async function getChartPrivateSearchIncident(payload: GetChartIncidentSearchPrivateDtoIn) {
	return send<ResponseDto<GetChartIncidentSearchPrivateDtoOut>, GetChartIncidentSearchPrivateDtoIn>(
		'mis.incident-search-private.getChart',
		payload
	).then((res) => res.data)
}

export async function getListIncidentSearchPrivate(payload: GetListIncidentSearchPrivateDtoIn) {
	return send<ResponseDto<GetListIncidentSearchPrivateDtoOut[]>, GetListIncidentSearchPrivateDtoIn>(
		'mis.incident-search-private.getList',
		payload
	).then((res) => res)
}

export async function getLocationIncidentSearchPrivate(payload: GetLocationIncidentSearchPrivateDtoIn) {
	return send<ResponseDto<GetLocationIncidentSearchPrivateDtoOut[]>, GetLocationIncidentSearchPrivateDtoIn>(
		'mis.incident-search-private.getLocation',
		payload
	).then((res) => res.data)
}

export async function getSummaryIncidentSearchPrivate(payload: GetSummaryIncidentSearchPrivateDtoIn) {
	return send<ResponseDto<GetSummaryIncidentSearchPrivateDtoOut[]>, GetSummaryIncidentSearchPrivateDtoIn>(
		'mis.incident-search-private.getSummary',
		payload
	).then((res) => res.data)
}

export async function disconnectLine(payload: DisconnectLineDtoIn) {
	return send<ResponseDto<DisconnectLineDtoOut>, DisconnectLineDtoIn>('mis.line.disconnect', payload).then(
		(res) => res.data
	)
}

export async function switchLineMenu(payload: SwitchLineMenuDtoIn) {
	return send<ResponseDto<SwitchLineMenuDtoOut>, SwitchLineMenuDtoIn>('mis.line-menu.switch', payload).then(
		(res) => res.data
	)
}

export async function getAddedLine() {
	return send<ResponseDto<GetAddedLineConnectListDtoOut[]>>('mis.line-connect.get').then((res) => res.data)
}

export async function searchLine(payload: SearchLineOADtoIn) {
	return send<ResponseDto<SearchLineOADtoOut[]>, SearchLineOADtoIn>('mis.line-connect.search', payload).then(
		(res) => res
	)
}

export async function addLine(payload: AddLineConnectDtoIn) {
	return send<ResponseDto<AddLineConnectDtoOut>, AddLineConnectDtoIn>('mis.line-connect.add', payload).then(
		(res) => res.data
	)
}

export function deleteLine(payload: DeleteLineConnectDtoIn) {
	return send<void, DeleteLineConnectDtoIn>('mis.line-connect.delete', payload)
}

export async function getUser() {
	return send<ResponseDto<UserProfileDto>>('mis.user.get').then((res) => res.data)
}

export async function getUserFile() {
	return send<GetUserFileDtoOut, void>('mis.user-file.getFile').then((res) => res.file)
}

export async function getMuni() {
	return send<ResponseDto<GetMunicipalityDtoOut[]>>('mis.municipality.get').then((res) => res.data)
}

export function listUserManagement(payload: ListUserManagementDtoIn) {
	return send<ResponseDto<ListUserManagementDtoOut[]>, ListUserManagementDtoIn>('mis.user-management.list', payload)
}

export function listUserNotInOfficerAreaUserManagement() {
	return send<ResponseDto<ListUserNotInOfficerAreaUserManagementDtoOut[]>>(
		'mis.user-management.listUserNotInOfficerArea'
	).then((res) => res.data)
}

export function getUserManagement(payload: GetUserManagementDtoIn) {
	return send<ResponseDto<GetUserManagementDtoOut>, GetUserManagementDtoIn>('mis.user-management.get', payload).then(
		(res) => res.data
	)
}

export function checkDuplicationEmailUserManagement(payload: CheckDuplicationEmailUserManagementDtoIn) {
	return send<ResponseDto<CheckDuplicationEmailUserManagementDtoOut>, CheckDuplicationEmailUserManagementDtoIn>(
		'mis.user-management.checkDuplicateEmail',
		payload
	).then((res) => res.data)
}

export function insertMappingUserManagement(payload: InsertMappingUserManagementDtoIn) {
	return send<void, InsertMappingUserManagementDtoIn>('mis.user-management.insertMapping', payload)
}

export function updateMappingUserManagement(payload: UpdateMappingUserManagementDtoIn) {
	return send<void, UpdateMappingUserManagementDtoIn>('mis.user-management.updateMapping', payload)
}

export function deleteMappingUserManagement(payload: DeleteMappingUserManagementDtoIn) {
	return send<void, DeleteMappingUserManagementDtoIn>('mis.user-management.deleteMapping', payload)
}

export function updateUserManagement(payload: UpdateUserManagementDtoIn) {
	return send<void, UpdateUserManagementDtoIn>('mis.user-management.update', payload)
}

export function updateUserNotInOfficerArea(payload: UpdateUserNotInOfficerAreaDtoIn) {
	return send<void, UpdateUserNotInOfficerAreaDtoIn>('mis.user-management.updateUserNotInOfficerArea', payload)
}

//#region
export function getManageIncident(payload: SearchIncidentOfficerDtoIn) {
	return send<ResponseDto<SearchIncidentOfficerDtoOut[]>, SearchIncidentOfficerDtoIn>(
		'mis.incident-officer.search',
		payload
	)
}

export function updateUrgentIncident(payload: UrgentIncidentOfficerDtoIn) {
	return send<ResponseDto<boolean>, UrgentIncidentOfficerDtoIn>('mis.incident-officer.urgent', payload)
}

//#endregion

export async function getUserList(payload: GetUserListIncidentProblemTypeConfigDtoIn) {
	return send<ResponseDto<GetUserListIncidentProblemTypeConfigDtoOut[]>>(
		'mis.incident-problem-type-config.getUserList',
		payload
	).then((res) => {
		return res.data
	})
}

export async function getApproverList(payload: GetApproverListIncidentProblemTypeConfigDtoIn) {
	return send<ResponseDto<GetApproverListIncidentProblemTypeConfigDtoOut[]>>(
		'mis.incident-problem-type-config.getApproverList',
		payload
	).then((res) => {
		return res.data
	})
}

export async function getConfigSearch(payload: SearchIncidentProblemTypeConfigDtoIn) {
	return send<ResponseDto<SearchIncidentProblemTypeConfigDtoOut[]>>(
		'mis.incident-problem-type-config.search',
		payload
	).then((res) => {
		return res
	})
}

export async function validateConfig(payload: CheckDuplicateIncidentProblemListProblemTypeConfigDtoIn) {
	return send<ResponseDto<CheckDuplicateIncidentProblemListProblemTypeConfigDtoOut>>(
		'mis.incident-problem-type-config.checkDuplicateProblem',
		payload
	).then((res) => {
		return res.data.isDuplicate
	})
}

export async function saveConfig(payload: UpsertIncidentProblemTypeConfigDtoIn) {
	return send<ResponseDto<boolean>, UpsertIncidentProblemTypeConfigDtoIn>(
		'mis.incident-problem-type-config.upsert',
		payload
	)
}

export async function deleteConfig(payload: DeleteIncidentProblemTypeConfigDtoIn) {
	return send<ResponseDto<boolean>, DeleteIncidentProblemTypeConfigDtoIn>(
		'mis.incident-problem-type-config.delete',
		payload
	)
}

export async function getIncidentProblemTypeConfigById(payload: GetIncidentProblemTypeConfigDtoIn) {
	return send<ResponseDto<GetIncidentProblemTypeConfigDtoOut>>('mis.incident-problem-type-config.get', payload).then(
		(res) => {
			return res.data
		}
	)
}

export async function incidentResolve(payload: ResolveIncidentOfficerDtoIn) {
	return send<ResponseDto<ResolveIncidentOfficerDtoOut>>('mis.incident-officer.resolve', payload)
}

export async function incidentAccept(payload: AcceptIncidentPeopleDtoIn) {
	return send<ResponseDto<boolean>>('mis.incident-people.accept', payload)
}

export async function incidentPeopleReject(payload: RejectIncidentPeopleDtoIn) {
	return send<ResponseDto<boolean>>('mis.incident-people.reject', payload)
}

export async function incidentOfficerReject(payload: RejectIncidentOfficerDtoIn) {
	return send<ResponseDto<boolean>>('mis.incident-officer.reject', payload)
}

export async function incidentCancel(payload: CancelIncidentOfficerDtoIn) {
	return send<ResponseDto<boolean>>('mis.incident-officer.cancel', payload)
}

export async function incidentPending(payload: PendingIncidentOfficerDtoIn) {
	return send<ResponseDto<boolean>>('mis.incident-officer.pending', payload)
}

export async function incidentAssign(payload: AssignIncidentOfficerDtoIn) {
	return send<ResponseDto<AssignIncidentOfficerDtoOut>>('mis.incident-officer.assign', payload)
}

export async function incidentUpdateAssign(payload: UpdateAssignIncidentOfficerDtoIn) {
	return send<ResponseDto<UpdateAssignIncidentOfficerDtoOut>>('mis.incident-officer.update-assign', payload)
}

export async function incidentCreateTask(payload: CreateTaskDtoIn) {
	return send<ResponseDto<boolean>>('mis.incident-officer.create-task', payload)
}

export async function incidentApprove(payload: ApproveIncidentOfficerDtoIn) {
	return send<ResponseDto<boolean>>('mis.incident-officer.approve', payload)
}

export function updatePeopleUser(payload: UpdatePeopleUserDtoIn) {
	return send<void, UpdatePeopleUserDtoIn>('mis.people-user.update', payload)
}

export function updateOfficerUser(payload: UpdateOfficerUserDtoIn) {
	return send<void, UpdateOfficerUserDtoIn>('mis.officer-user.update', payload)
}

export function getOfficer() {
	return send<ResponseDto<OfficerGetDto>>('mis.officer-user.get').then((res) => res.data)
}

export async function getUnassigned(payload: GetUnassignedDtoIn) {
	return send<ResponseDto<GetUnassignedDtoOut[]>>('mis.incident-officer.get-unassigned', payload)
}

//#region Division Config

export function getConfigDivisionSearch(payload: SearchConfigDivisionDtoIn) {
	return send<ResponseDto<SearchConfigDivisionDtoOut[]>, SearchConfigDivisionDtoIn>(
		'mis.config-division.search',
		payload
	)
}

export function getConfigDivision(payload: any) {
	return send<ResponseDto<GetConfigDivisionDtoOut>, GetConfigDivisionDtoIn>('mis.config-division.get', payload).then(
		(res) => res.data
	)
}

export function createConfigDivision(payload: CreateConfigDivisionDtoIn) {
	delete cacheLookupData['user-division']
	return send<void, CreateConfigDivisionDtoIn>('mis.config-division.create', payload)
}

export function updateConfigDivision(payload: UpdateConfigDivisionDtoIn) {
	delete cacheLookupData['user-division']
	return send<void, UpdateConfigDivisionDtoIn>('mis.config-division.update', payload)
}

export function deleteConfigDivision(payload: DeleteConfigDivisionDtoIn) {
	delete cacheLookupData['user-division']
	return send<void, DeleteConfigDivisionDtoIn>('mis.config-division.delete', payload)
}

export function checkDuplicateConfigDivision(payload: CheckConfigDivisionDtoIn) {
	return send<ResponseDto<CheckConfigDivisionDtoOut>, CheckConfigDivisionDtoIn>(
		'mis.config-division.checkDuplicate',
		payload
	)
}

//#endregion Division Config

//#region Problem Config
// export function getConfigProblemSearch(payload: SearchConfigProblemDtoIn) {
// 	return send<ResponseDto<SearchConfigProblemDtoOut[]>, SearchConfigProblemDtoIn>(
// 		'mis.config-problem.search',
// 		payload
// 	)
// }

export function getConfigProblemSearch(payload: SearchConfigProblemDtoIn) {
	return send<ResponseDto<SearchConfigProblemDtoOut[]>, SearchConfigProblemDtoIn>(
		'mis.config-problem.search',
		payload
	).then((res) => {
		return res
	})
}

export function getConfigProblem(payload: GetConfigProblemDtoIn) {
	return send<ResponseDto<GetConfigProblemDtoOut>, GetConfigProblemDtoIn>('mis.config-problem.get', payload).then(
		(res) => res.data
	)
}

export function createConfigProblem(payload: CreateConfigProblemDtoIn) {
	delete cacheLookupData['inc-problem-type']
	delete cacheLookupData['inc-problem-subtype']
	return send<void, CreateConfigProblemDtoIn>('mis.config-problem.create', payload)
}

export function updateConfigProblem(payload: UpdateConfigProblemDtoIn) {
	delete cacheLookupData['inc-problem-type']
	delete cacheLookupData['inc-problem-subtype']
	return send<void, UpdateConfigProblemDtoIn>('mis.config-problem.update', payload)
}

export function deleteConfigProblem(payload: DeleteConfigProblemDtoIn) {
	delete cacheLookupData['inc-problem-type']
	delete cacheLookupData['inc-problem-subtype']
	return send<void, DeleteConfigProblemDtoIn>('mis.config-problem.delete', payload)
}

export function checkDuplicateConfigProblem(payload: CheckConfigProblemDtoIn) {
	return send<ResponseDto<CheckConfigProblemDtoOut>, CheckConfigProblemDtoIn>(
		'mis.config-problem.checkDuplicate',
		payload
	)
}

export function reorderConfigProblem(payload: ReorderConfigProblemDtoIn[]) {
	return send<void, ReorderConfigProblemDtoIn[]>('mis.config-problem.reorder', payload)
}

//#endregion

//#region Incident Review
export function updateIncidentReview(payload: UpdateIncidentReviewDtoIn) {
	return send<void, UpdateIncidentReviewDtoIn>('mis.incident-review.update', payload)
}
//#endregion Incident Review

//#region  Summary
export function searchOperationPerson(payload: SearchOperationPersonDtoIn) {
	return send<ResponseDto<SearchOperationPersonDtoOut[]>, SearchOperationPersonDtoIn>(
		'mis.operation-person.search',
		payload
	)
}

export function getChartOperationPerson(payload: GetChartOperationPersonDtoIn) {
	return send<ResponseDto<GetChartOperationPersonDtoOut>, GetChartOperationPersonDtoIn>(
		'mis.operation-person-chart.get',
		payload
	).then((res) => res.data)
}

export function searchOperationProblem(payload: SearchOperationProblemDtoIn) {
	return send<ResponseDto<SearchOperationProblemDtoOut[]>, SearchOperationProblemDtoIn>(
		'mis.operation-problem.search',
		payload
	)
}

export function getChartOperationProblem(payload: GetChartOperationProblemDtoIn) {
	return send<ResponseDto<GetChartOperationProblemDtoOut>, GetChartOperationProblemDtoIn>(
		'mis.operation-problem-chart.get',
		payload
	).then((res) => res.data)
}

export function searchDurationDivision(payload: SearchDurationDivisionDtoIn) {
	return send<ResponseDto<SearchDurationDivisionDtoOut[]>, SearchDurationDivisionDtoIn>(
		'mis.duration-division.search',
		payload
	)
}

export function getChartDurationDivision(payload: GetChartDurationDivisionDtoIn) {
	return send<ResponseDto<GetChartDurationDivisionDtoOut[]>, GetChartDurationDivisionDtoIn>(
		'mis.duration-division-chart.get',
		payload
	).then((res) => res.data)
}

export function getChartDurationProblem(payload: GetChartDurationProblemDtoIn) {
	return send<ResponseDto<GetChartDurationProblemDtoOut>, GetChartDurationProblemDtoIn>(
		'mis.duration-problem-chart.get',
		payload
	).then((res) => res.data)
}

export function searchDurationProblem(payload: SearchDurationProblemDtoIn) {
	return send<ResponseDto<SearchDurationProblemDtoOut[]>, SearchDurationProblemDtoIn>(
		'mis.duration-problem.search',
		payload
	)
}

//#endregion  Summary

//#region Incident Notification
export async function getIncidentNotification(payload: GetIncidentNotificationDtoIn) {
	return send<ResponseDto<GetIncidentNotificationDtoOut[]>, GetIncidentNotificationDtoIn>(
		'mis.incident.notification.get',
		payload
	).then((res) => res)
}

export async function deleteIncidentNotification() {
	return send<ResponseDto<DeleteIncidentNotificationDtoOut>>('mis.incident.notification.delete').then(
		(res) => res.data
	)
}

export async function getIncidentNotificationCount() {
	return send<ResponseDto<GetIncidentNotificationCountDtoOut>>('mis.incident.notification-count.get').then(
		(res) => res.data
	)
}

export async function clearIncidentNotificationCount() {
	return send<ResponseDto<ClearIncidentNotificationCountDtoOut>>('mis.incident.notification-count.clear').then(
		(res) => res.data
	)
}
//#endregion Incident Notification

export async function getDefaultLineOA() {
	return send<ResponseDto<GetDefaultLineOADtoOut>>('mis.line-oa-default.get').then((res) => res)
}
export async function getLineOA(payload: GetLineOADtoIn) {
	return send<ResponseDto<GetLineOADtoOut>>('mis.line-oa.get', payload).then((res) => res)
}
export async function getLineAuth(payload: GetLineAuthDtoIn) {
	return send<ResponseDto<GetLineAuthDtoOut>, GetLineAuthDtoIn>('mis.line-auth.get', payload).then((res) => res)
}

export async function getMuniSystemConfig(payload: GetMuniSystemConfigDtoIn) {
	return send<ResponseDto<GetMuniSystemConfigDtoOut>>('mis.muni-system-config.get', payload).then((res) => res.data)
}
export async function updateMuniSystemConfig(payload: UpdateMuniSystemConfigDtoIn) {
	return send<ResponseDto<UpdateMuniSystemConfigDtoOut>>('mis.muni-system-config.update', payload).then(
		(res) => res.data
	)
}
