export const gaEvent = {
	//#region BOTH
	navbarClickMenuOverview: 'navbar_click_menu_overview',
	navbarClickMenuNotification: 'navbar_click_menu_notification',
	navbarClickMenuProfile: 'navbar_click_menu_profile',
	overviewSearchFillInComplaintNumber: 'overview_search_fill_in_complaint_number',
	overviewSearchFillInAreaBoundary: 'overview_search_fill_in_area_boundary',
	overviewSearchSelectStatus: 'overview_search_select_status',
	overviewSearchSelectMunicipality: 'overview_search_select_municipality',
	overviewSearchSelectYear: 'overview_search_select_year',
	overviewSearchSelectMonth: 'overview_search_select_month',
	overviewClickSearchButton: 'overview_click_search_button',
	overviewClickCreateNewComplaint: 'overview_click_create_new_complaint',
	overviewClickSelectPinInMap: 'overview_click_select_pin_in_map',
	overviewClickDetailPinInMap: 'overview_click_detail_pin_in_map',
	overviewClickSelectComplaintInListResult: 'overview_click_select_complaint_in_list_result',
	navbarClickSubmenuMyProfileInProfile: 'navbar_click_submenu_my_profile_in_profile',
	navbarClickSubmenuConnectLineInProfile: 'navbar_click_submenu_connect_line_in_profile',
	navbarClickSubmenuLogOutInProfile: 'navbar_click_submenu_log_out_in_profile',
	profileFillInName: 'profile_fill_in_name',
	profileFillInSurname: 'profile_fill_in_surname',
	profileFillInTelephone: 'profile_fill_in_telephone',
	profileClickSave: 'profile_click_save',
	complaintDetailsClickBackButton: 'complaint_details_click_back_button',
	navbarClickSelectComplaintInListNotificationIn: 'navbar_click_select_complaint_in_list_notification_in',
	navbarClickButtonDeleteAllInListNotificationIn: 'navbar_click_button_delete_all_in_list_notification_in',
	//#endregion BOTH

	//#region PEOPLE
	navbarClickMenuMylist: 'navbar_click_menu_mylist',
	mylistSearchFillInComplaintNumber: 'mylist_search_fill_in_complaint_number',
	mylistSearchSelectStatus: 'mylist_search_select_status',
	mylistSearchSelectDate: 'mylist_search_select_date',
	mylistSearchFillInTag: 'mylist_search_fill_in_tag',
	mylistSearchSelectMajorProblem: 'mylist_search_select_major_problem',
	mylistSearchSelectMinorProblem: 'mylist_search_select_minor_problem',
	mylistSearchFillInAreaBoundary: 'mylist_search_fill_in_area_boundary',
	mylistClickSearchButton: 'mylist_click_search_button',
	mylistClickClearDataButton: 'mylist_click_clear_data_button',
	mylistClickCreateNewComplaint: 'mylist_click_create_new_complaint',
	mylistClickSelectPinInMap: 'mylist_click_select_pin_in_map',
	mylistClickSelectComplaintInListResult: 'mylist_click_select_complaint_in_list_result',
	complaintDetailsClickReturnEditButton: 'complaint_details_click_return_edit_button',
	complaintDetailsClickCompleteButton: 'complaint_details_click_complete_button',
	complaintDetailsModalReturnEditFillInAdditionalComments:
		'complaint_details_modal_return_edit_fill_in_additional_comments',
	complaintDetailsModalReturnEditClickBackButton: 'complaint_details_modal_return_edit_click__back_button',
	complaintDetailsModalReturnEditClickConfirmButton: 'complaint_details_modal_return_edit_click__confirm_button',
	complaintDetailsModalConpleteClickBackButton: 'complaint_details_modal_conplete_click__back_button',
	complaintDetailsModalCompleteClickConfirmButton: 'complaint_details_modal_complete_click__confirm_button',
	complaintDetailsClickRatingButton: 'complaint_details_click_rating_button',
	complaintDetailsModalRatingSelectStar: 'complaint_details_modal_rating_select_star',
	complaintDetailsModalRatingFillInAdditionalComments: 'complaint_details_modal_rating_fill_in_additional_comments',
	complaintDetailsModalRatingClickBackButton: 'complaint_details_modal_rating_click__back_button',
	complaintDetailsModalRatingClickConfirmButton: 'complaint_details_modal_rating_click__confirm_button',
	complaintCreateBackTop: 'complaint_create_back_top',
	complaintCreatePhotoUpload: 'complaint_create_photo_upload',
	complaintCreateLocationSearch: 'complaint_create_location_search',
	complaintCreateSelMapLayer: 'complaint_create_sel_map_layer',
	complaintCreateSelCurLocation: 'complaint_create_sel_cur_location',
	complaintCreateScroll: 'complaint_create_scroll',
	complaintCreateSelInvalid: 'complaint_create_sel_invalid',
	complaintCreateSelMainType: 'complaint_create_sel_main_type',
	complaintCreateSelSubType: 'complaint_create_sel_sub_type',
	complaintCreateTypeDescription: 'complaint_create_type_description',
	complaintCreateTypeTag: 'complaint_create_type_tag',
	complaintCreateTypeName: 'complaint_create_type_name',
	complaintCreateTypeTel: 'complaint_create_type_tel',
	complaintCreateTypeEmail: 'complaint_create_type_email',
	complaintCreateBackBottom: 'complaint_create_back_bottom',
	complaintCreateClickProceed: 'complaint_create_click_proceed',
	complaintCreateModalBack: 'complaint_create_modal_back',
	complaintCreateModalProceed: 'complaint_create_modal_proceed',
	//#endregion PEOPLE

	//#region
	navbarClickMenuManageComplaint: 'navbar_click_menu_manage_complaint',
	navbarClickMenuManageUser: 'navbar_click_menu_manage_user',
	navbarClickMenuManageSystem: 'navbar_click_menu_manage_system',
	overviewClickComplaintReportButton: 'overview_click_complaint_report_button',
	overviewClickJobOfDeptReportButton: 'overview_click_job_of_dept_report_button',
	manageComplaintSearchFillInComplaintNumber: 'manage_complaint_search_fill_in_complaint_number',
	manageComplaintSearchSelectStatus: 'manage_complaint_search_select_status',
	manageComplaintSearchSelectDate: 'manage_complaint_search_select_date',
	manageComplaintSearchFillInTag: 'manage_complaint_search_fill_in_tag',
	manageComplaintSearchSelectMajorProblem: 'manage_complaint_search_select_major_problem',
	manageComplaintSearchSelectMinorProblem: 'manage_complaint_search_select_minor_problem',
	manageComplaintSearchFillInAreaBoundary: 'manage_complaint_search_fill_in_area_boundary',
	manageComplaintClickSearchButton: 'manage_complaint_click_search_button',
	manageComplaintClickClearDataButton: 'manage_complaint_click_clear_data_button',
	manageComplaintClickShowResultsInAListFormat: 'manage_complaint_click_show_results_in_a_list_format',
	manageComplaintClickShowResultsInAMapFormat: 'manage_complaint_click_show_results_in_a_map_format',
	manageComplaintClickSelectComplaintInListResult: 'manage_complaint_click_select_complaint_in_list_result',
	manageComplaintClickSelectComplaintInMapResult: 'manage_complaint_click_select_complaint_in_map_result',
	manageComplaintClickDetailPinInMap: 'manage_complaint_click_detail_pin_in_map',
	complaintDetailsFillInTroubleshootingDetails: 'complaint_details_fill_in_troubleshooting_details',
	complaintDetailsFillInTroubleshootingDate: 'complaint_details_fill_in_troubleshooting_date',
	complaintDetailsFillInUploadImage: 'complaint_details_fill_in_upload_image',
	complaintDetailsClickCancelComplaintButton: 'complaint_details_click_cancel_complaint_button',
	complaintDetailsClickDelayComplaintButton: 'complaint_details_click_delay_complaint_button',
	complaintDetailsClickForwardComplaintButton: 'complaint_details_click_forward_complaint_button',
	complaintDetailsClickConfirmCompleteButton: 'complaint_details_click_confirm_complete_button',
	complaintDetailsModalCancelComplaintFillInComments: 'complaint_details_modal_cancel_complaint_fill_in_comments',
	complaintDetailsModalCancelComplaintClickBackButton: 'complaint_details_modal_cancel_complaint_click_back_button',
	complaintDetailsModalCancelComplaintClickConfirmButton:
		'complaint_details_modal_cancel_complaint_click_confirm_button',
	complaintDetailsModalDelayComplaintFillInStartDateAgain:
		'complaint_details_modal_delay_complaint_fill_in_start_date_again',
	complaintDetailsModalDelayComplaintFillInCause: 'complaint_details_modal_delay_complaint_fill_in_cause',
	complaintDetailsModalDelayComplaintClickBackButton: 'complaint_details_modal_delay_complaint_click_back_button',
	complaintDetailsModalDelayComplaintClickConfirmDelayButton:
		'complaint_details_modal_delay_complaint_click_confirm_delay_button',
	complaintDetailsModalForwardComplaintSelectDept: 'complaint_details_modal_forward_complaint_select_dept',
	complaintDetailsModalForwardComplaintSelectRole: 'complaint_details_modal_forward_complaint_select_role',
	complaintDetailsModalForwardComplaintSelectResponsiblePerson:
		'complaint_details_modal_forward_complaint_select_responsible_person',
	complaintDetailsModalForwardComplaintFillInComment: 'complaint_details_modal_forward_complaint_fill_in_comment',
	complaintDetailsModalForwardComplaintClickBackButton: 'complaint_details_modal_forward_complaint_click_back_button',
	complaintDetailsModalForwardComplaintClickConfirmForwardButton:
		'complaint_details_modal_forward_complaint_click_confirm_forward_button',
	complaintDetailsFillInGovApproveComment: 'complaint_details_fill_in_gov_approve_comment',
	complaintDetailsClickGovApproveButton: 'complaint_details_click_gov_approve_button',
	complaintDetailsModalGovApproveComplaintClickBackButton:
		'complaint_details_modal_gov_approve_complaint_click_back_button',
	complaintDetailsModalGovApproveComplaintClickConfirmApproveButton:
		'complaint_details_modal_gov_approve_complaint_click_confirm_approve_button',
	manageUserSearchFillInGovName: 'manage_user_search_fill_in_gov_name',
	manageUserSearchFillInGovEmail: 'manage_user_search_fill_in_gov_email',
	manageUserSearchFillInGovTel: 'manage_user_search_fill_in_gov_tel',
	manageUserSearchSelectMunicipality: 'manage_user_search_select_municipality',
	manageUserSearchSelectGovLevel: 'manage_user_search_select_gov_level',
	manageUserSearchSelectGovRole: 'manage_user_search_select_gov_role',
	manageUserClickSearchButton: 'manage_user_click_search_button',
	manageUserClickSelectUserInListResult: 'manage_user_click_select_user_in_list_result',
	manageUserClickAddUserButton: 'manage_user_click_add_user_button',
	manageUserClickAddUserFromCenterButton: 'manage_user_click_add_user_from_center_button',
	manageUserModalAddUserClickBackButton: 'manage_user_modal_add_user_click_back_button',
	manageUserModalAddUserClickSaveButton: 'manage_user_modal_add_user_click_save_button',
	manageUserModalAddUserFromCenterClickBackButton: 'manage_user_modal_add_user_from_center_click_back_button',
	manageUserModalAddUserFromCenterClickSaveButton: 'manage_user_modal_add_user_from_center_click_save_button',
	navbarClickSubmenuManageDeptInManageSystem: 'navbar_click_submenu_manage_dept_in_manage_system',
	navbarClickSubmenuManageProblemLineInManageSystem: 'navbar_click_submenu_manage_problem_line_in_manage_system',
	navbarClickSubmenuManageResponsiblePersonInManageSystem:
		'navbar_click_submenu_manage_responsible_person_in_manage_system',
	manageDeptSearchSelectMunicipality: 'manage_dept_search_select_municipality',
	manageDeptSearchSelectDept: 'manage_dept_search_select_dept',
	manageDeptClickSearchButton: 'manage_dept_click_search_button',
	manageDeptClickAddDeptButton: 'manage_dept_click_add_dept_button',
	manageDeptClickSelectDeptInListResult: 'manage_dept_click_select_dept_in_list_result',
	manageDeptAddEditClickBackButton: 'manage_dept_add/edit_click_back_button',
	manageDeptAddEditClickSaveButton: 'manage_dept_add/edit_click_save_button',
	manageProblemSearchSelectMunicipality: 'manage_problem_search_select_municipality',
	manageProblemSearchSelectMajorProblem: 'manage_problem_search_select_major_problem',
	manageProblemSearchSelectMinorProblem: 'manage_problem_search_select_minor_problem',
	manageProblemClickSearchButton: 'manage_problem_click_search_button',
	manageProblemClickAddDeptButton: 'manage_problem_click_add_dept_button',
	manageProblemClickSelectProblemInListResult: 'manage_problem_click_select_problem_in_list_result',
	manageProblemAddEditClickBackButton: 'manage_problem_add/edit_click_back_button',
	manageProblemAddEditClickSaveButton: 'manage_problem_add/edit_click_save_button',
	manageResponsiblePersonSearchSelectMunicipality: 'manage_responsible_person_search_select_municipality',
	manageResponsiblePersonSearchSelectMajorProblem: 'manage_responsible_person_search_select_major_problem',
	manageResponsiblePersonClickSearchButton: 'manage_responsible_person_click_search_button',
	manageResponsiblePersonClickAddDeptButton: 'manage_responsible_person_click_add_dept_button',
	manageResponsiblePersonClickSelectResponsiblePersonInListResult:
		'manage_responsible_person_click_select_responsible_person_in_list_result',
	manageResponsiblePersonAddEditClickBackButton: 'manage_responsible_person_add/edit_click_back_button',
	manageResponsiblePersonAddEditClickSaveButton: 'manage_responsible_person_add/edit_click_save_button',
	manageComplaintSortComplaintDate: 'manage_complaint_sort_complaint_date',
	manageComplaintSortComplaintNumber: 'manage_complaint_sort_complaint_number',
	manageComplaintSortComplaintIssuer: 'manage_complaint_sort_complaint_issuer',
	manageComplaintSortComplaintDevice: 'manage_complaint_sort_complaint_device',
	manageComplaintSortComplaintMainType: 'manage_complaint_sort_complaint_main_type',
	manageComplaintSortComplaintSubType: 'manage_complaint_sort_complaint_sub_type',
	manageComplaintSortComplaintDescription: 'manage_complaint_sort_complaint_description',
	manageComplaintSortComplaintAddress: 'manage_complaint_sort_complaint_address',
	manageComplaintSortComplaintStatus: 'manage_complaint_sort_complaint_status',
	manageComplaintPagination: 'manage_complaint_pagination',
	manageComplaintPaginationType: 'manage_complaint_pagination_type',
	manageComplaintSortComplaintIsUrgent: 'manage_complaint_pagination_is_urgent',
}
