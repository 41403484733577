import { Modal } from '@/components/common/modal/Modal'
import { AuthType } from '@interface/config/am.config'
import { EventPatternType, MessagePatternType } from '@interface/config/microservice.config'
import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import CancelablePromise from 'cancelable-promise'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()
const { apiUrl, basePath } = publicRuntimeConfig

export const axiosInstance = axios.create({
	baseURL: apiUrl,
})

axiosInstance.interceptors.response.use(
	(response) => {
		return response
	},
	async function (error) {
		if (isAxiosError(error) && error.config && error.response?.status === 401) {
			try {
				const originalRequest = error.config as any
				if (!originalRequest?._retry) {
					originalRequest._retry = true
					const { updateToken } = await import('@arv-bedrock/auth')
					const result = await updateToken()
					const newToken = `${AuthType.Arv}-${result?.access_token}`
					updateAccessToken(newToken)
					return axiosInstance({
						...originalRequest,
						headers: {
							...originalRequest.headers,
							authorization: `Bearer ${newToken}`,
						},
					}).catch((err) => {
						const href =
							typeof location !== 'undefined' ? `${location.protocol}//${location.host}${basePath}` : ''
						return new Promise((resolve) => {
							Modal.confirm({
								closable: false,
								okCancel: false,
								okText: 'ตกลง',
								title: 'ระยะเวลาการใช้งานหมดอายุ',
								content: 'โปรดเข้าสู่ระบบอีกครั้ง',
								onOk: () =>
									import('@arv-bedrock/auth')
										.then(({ doLogout }) => doLogout(href))
										.then(() => resolve(err)),
							})
						})
					})
				}
			} catch (err) {
				return Promise.reject(err)
			}
		}
		return Promise.reject(error)
	}
)

export const send = <TOutput = any, TInput = any>(
	service: MessagePatternType,
	params?: TInput,
	config?: AxiosRequestConfig
): CancelablePromise<TOutput> => {
	try {
		const controller = new AbortController()
		return new CancelablePromise((resolve, reject, onCancel) => {
			axiosInstance
				.post<TOutput>('', params, {
					...config,
					signal: controller.signal,
					params: { service },
					headers: { 'x-service-name': service },
				})
				.then((res) => resolve(res.data))
				.catch((err) => reject(err))
			onCancel(() => controller.abort())
		})
	} catch (error) {
		if (isAxiosError(error)) {
			console.error(error)
		}
		throw error
	}
}

export const emit = async <TInput = any>(event: EventPatternType, params?: TInput): CancelablePromise<any> => {
	try {
		const controller = new AbortController()
		return new CancelablePromise((resolve, reject, onCancel) => {
			axiosInstance
				.post<any>('', params, {
					signal: controller.signal,
					headers: { 'x-event-name': event },
				})
				.then((res) => resolve(res.data))
				.catch((err) => reject(err))
			onCancel(() => controller.abort())
		})
	} catch (error) {
		if (isAxiosError(error)) {
			console.error(error)
		}
		throw error
	}
}

export function updateAccessToken(token?: string | void) {
	if (token) {
		axiosInstance.defaults.headers.common.authorization = 'Bearer ' + token
	} else {
		axiosInstance.defaults.headers.common.authorization = ''
	}
}

export function getAccesssToken() {
	return axiosInstance.defaults.headers.common.authorization?.toString().split(' ')?.[1]
}

export function updateApiKey(apiKey?: string | void) {
	if (apiKey) {
		axiosInstance.defaults.headers.common['x-api-key'] = apiKey
	} else {
		axiosInstance.defaults.headers.common['x-api-key'] = ''
	}
}

export function getApiKey() {
	return axiosInstance.defaults.headers.common['x-api-key']
}

function isAxiosError<TInput = unknown, TOutput = any>(obj: unknown): obj is AxiosError<TInput, TOutput> {
	return obj instanceof AxiosError
}
